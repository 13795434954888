import React from 'react'
import PropTypes from 'prop-types'
import StyledNavItem from './style'
import { getUrl } from '../../../helpers/url'
import { Button } from '../../../components/Button'

const NavItem = props => {
  const { children, onClick, cssType, href, active } = props
  return onClick ? (
    <StyledNavItem.NavItemButton {...props}>
      {children}
    </StyledNavItem.NavItemButton>
  ) : href && cssType === 'icon' ? (
    <StyledNavItem.NavItemLink to={getUrl(href)}>
      {children}
    </StyledNavItem.NavItemLink>
  ) : href && cssType === 'button' ? (
    <Button to={getUrl(href, true)}>{children}</Button>
  ) : (
    <StyledNavItem active={active}>{children}</StyledNavItem>
  )
}

NavItem.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  href: PropTypes.string,
  cssType: PropTypes.string,
  active: PropTypes.bool,
}

export { NavItem }
