import styled, { css } from 'styled-components'
import { darken } from 'polished'
import {
  colors,
  radius,
  typography,
  cssEasings,
  spacing,
  mq,
} from '../../styles/utilities/variables'
import { Link } from '../Link'

//const inSpeed = 0.5
const outSpeed = 0.3

const getColor = theme => {
  if (theme === 'greenTheme') {
    return colors.darkGreen
  }
  return colors.black
}

const buttonStyle = () => css`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${props => (props.small ? spacing.small : spacing.base)} ${
  spacing.medium
};
  border-radius: ${radius.large};
  border: none;
  color: ${props =>
    props.themeColor
      ? getColor(props.themeColor)
      : props.secondary
      ? colors.primary
      : colors.white};
  background-color: ${props =>
    props.themeColor
      ? colors[props.themeColor].secondary
      : props.secondary
      ? colors.secondary
      : colors.primary};

  ${props =>
    props.border &&
    css`
      border: 1px solid ${colors.primary};
    `}

  transition: background-color ${outSpeed}s ${cssEasings.easeInOutCubic},
  color ${outSpeed}s ${cssEasings.easeInOutCubic},
  transform 0.1s ${cssEasings.easeInOutCubic};
  overflow: hidden;
  //min-width: 220px;

  /* Newly changed */
  font-size: ${typography.sizes.xSmall};
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  ${props =>
    props.align === 'center' &&
    css`
      margin: 0 auto;
      display: inline-block;
    `};

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    background-color: ${props =>
      props.themeColor
        ? darken(0.1, colors[props.themeColor].secondary)
        : props.secondary
        ? darken(0.1, colors.secondary)
        : darken(0.1, colors.primary)};
    border-color: ${props =>
      props.themeColor
        ? darken(0.1, colors[props.themeColor].secondary)
        : props.secondary
        ? darken(0.1, colors.secondary)
        : darken(0.1, colors.primary)};
    color: ${props =>
      props.themeColor
        ? darken(0.1, getColor(props.themeColor))
        : props.secondary
        ? darken(0.1, colors.primary)
        : colors.white};
  }



  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

export const StyledButton = styled.button`
  ${buttonStyle};
`
StyledButton.a = styled(Link)`
  ${buttonStyle};
`
export default StyledButton
