import styled, { css } from 'styled-components'
import {
  spacing,
  mq,
  colors,
  typography,
} from '../../styles/utilities/variables'
import StyledText from '../Text/style'
import StyledBreadcrumbs from '../Breadcrumbs/style'
import StyledContainer from '../Container/style'
import StyledAccordionListItem from '../AccordionListItem/style'
import { StyledTestFreaks } from '../Testfreaks/style'

const StyledPageContent = styled.section`
  position: relative;
  padding: ${props => (props.top ? spacing.xxLarge : 0)} 0 ${spacing.xxLarge} 0;

  @media ${mq.tablet} {
    padding: ${props => (props.top ? spacing.xLarge : 0)} 0 ${spacing.xLarge} 0;
    border-top: none;
  }

  @media ${mq.mobile} {
    padding: ${props => (props.top ? spacing.medium : 0)} 0 ${spacing.medium} 0;
  }

  /** Template styling for tjäna-pengar page */
  ${props =>
    props.template === 'earnMoney' &&
    css`
      padding: ${props => (props.top ? spacing.xxLarge : 0)} 0 0 0;

      ${StyledAccordionListItem.Title} {
        font-weight: bold;
        font-size: ${typography.sizes.base};
      }

      @media ${mq.tablet} {
        padding: ${props => (props.top ? spacing.xLarge : 0)} 0 0 0;
        border-top: none;
        ${StyledContainer} {
          padding: 0 ${spacing.medium};
        }
      }

      @media ${mq.mobile} {
        padding: ${props => (props.top ? spacing.medium : 0)} 0 0 0;

        ${StyledText} {
          font-size: 1.5rem;
        }
        ${StyledAccordionListItem} {
          padding: 0;
        }
      }

      ${StyledBreadcrumbs} {
        color: ${colors.white};
        a {
          color: ${colors.white};
        }
      }
      ${StyledText} {
        font-weight: 500;
      }

      ${StyledTestFreaks} {
        .seller-header {
          display: none !important;
        }
        h1 {
          color: ${colors.darkPink} !important;
        }
        #testfreaks-seller-reviews {
          @media ${mq.mobile} {
            .seller-slider {
              padding: 0 !important;
            }
          }
          .slider-prev,
          .slider-next {
            background-color: ${colors.pink} !important;
          }
          .review-block {
            background-color: transparent !important;
          }
        }
      }
    `}
`

StyledPageContent.Container = styled.div`
  margin: 0 auto;
  max-width: 650px;
  ${StyledText} {
    overflow-wrap: break-word; // fix for long link
  }
`

export default StyledPageContent

