import styled from 'styled-components'
import { typography, mq } from '../../../styles/utilities/variables'

const StyledPreamble = styled.p`
  font-family: ${typography.secondaryFont};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: ${props => (props.size ? props.size : typography.sizes.medium)};
  line-height: ${props =>
    props.lineHeight ? props.lineHeight : typography.lineHeights.medium};
  width: ${props => (props.width ? props.width : '100%')};
  color: ${props => (props.color ? props.color : 'inherit')};
  text-align: ${props => (props.center ? 'center' : 'left')};

  @media ${mq.tablet} {
    font-size: ${typography.sizes.base};
    line-height: 1.5;
  }
`

export default StyledPreamble
