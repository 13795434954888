import React from 'react'
import PropTypes from 'prop-types'
import StyledButton from './style'

const Button = props => {
  const {
    children,
    to,
    type,
    primary,
    secondary,
    border,
    borderMobile,
    handleClick,
    disabled,
    align,
    small,
    target,
    themeColor,
  } = props

  const Component = to ? StyledButton.a : StyledButton

  return (
    <Component
      className={'button'}
      to={to}
      type={type}
      primary={primary}
      secondary={secondary}
      disabled={disabled}
      onClick={handleClick}
      border={border}
      bordermobile={borderMobile}
      align={align}
      small={small}
      target={target}
      themeColor={themeColor}
    >
      {children}
    </Component>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  to: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  handleClick: PropTypes.func,
  themeColor: PropTypes.string,
}

export { Button }
