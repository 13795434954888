import styled, { css } from 'styled-components'
import StyledIcon from '../../../components/Icon/style'
import { Link } from '../../../components/Link'
import {
  colors,
  typography,
  spacing,
} from '../../../styles/utilities/variables'

const buttonStyle = () => css`
  position: relative;
  display: flex;
  align-items: center;
  font-family: ${typography.secondaryFont};
  font-size: ${typography.sizes.small};
  font-weight: 500;
  color: ${colors.black};
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  position: relative;

  &[aria-current] {
    font-weight: 600;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &:not(:last-child) {
    margin-right: ${spacing.medium};
  }
  ${StyledIcon} {
    margin-left: ${spacing.xSmall};
    transform: ${props => (props.open ? `rotate(180deg)` : `rotate(0deg)`)};
    transition: all 0.2s ease;
  }
`

const StyledNavItem = styled.div`
  ${buttonStyle}
`

StyledNavItem.NavItemButton = styled.button`
  ${buttonStyle}

  > span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colors.black};
    color: ${colors.white};
    font-size: 1rem;
    bottom: 0;
    height: 13px;
    width: 100%;
    letter-spacing: 0.1em;
    border-radius: 1px;
  }
`
StyledNavItem.NavItemLink = styled(Link)`
  ${buttonStyle}

  & .lockClosed {
    margin-right: ${spacing.xxSmall};
  }
`

export default StyledNavItem
