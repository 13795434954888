import styled, { css } from 'styled-components'
import { colors, mq, spacing } from '../../styles/utilities/variables'
import StyledImage from '../Image/style'
import StyledVideo from '../Video/style'

const cardStyle = () => css`
  position: relative;
  width: 100%;
  background-color: ${props =>
    props.image ? colors.transparent : colors.grey};
  min-height: ${props => props.height ?? 'auto'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacing.xxxLarge} 0;
  max-height: 82vh;
    ${props =>
      props.mediaType === 'video' &&
      css`
        aspect-ratio: 16/9;
      `}
  @media ${mq.desktop} {
    padding: ${spacing.xxLarge} 0;
  }

  @media ${mq.mobile} {
    min-height: auto;
    padding: 0;
    
  }

  @media ${mq.mobileSmall} {
    ${props =>
      props.mediaType === 'video' &&
      css`
        aspect-ratio: 4/6;
      `}
  }

  ${StyledImage}, ${StyledVideo} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    height: 100%;
    padding-bottom: 0;
    filter: brightness(0.8);

    picture,
    img {
      height: 100%;

      .no-objectfit & {
        height: auto;
      }
    }
  }
  ${StyledVideo} {
    filter: brightness(1);
  }

  ${props =>
    props.contentAlignment === 'center' &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;

      @media ${mq.tablet} {
        flex-direction: column-reverse;
      }
    `}
`

const StyledImageCardDiv = styled.div`
  ${cardStyle}
`

StyledImageCardDiv.ContentAndButtonWrapper = styled.div`
 ${props =>
   props.mediaType === 'video' &&
   css`
     margin-top: auto;
     padding-bottom: 0;
   `}
  @media ${mq.tablet} {
    width: 100%;
    padding: ${spacing.xLarge} ${spacing.medium};

    ${props =>
      !props.subContent &&
      css`
        padding-bottom: 0;
      `}
  }
`

const StyledImageCardA = styled.a`
  ${cardStyle}
`

export { StyledImageCardDiv, StyledImageCardA }
